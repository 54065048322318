<template>
	<!-- header with logo and menu -->
	<div class="container-fluid bg-green text-center text-white">
		<div class="container">
			<!-- logo -->
			<div class="row">
				<div class="col-md-5">
					<router-link to="/">
						<img src="@/assets/Logo-PBP-Wit-hor.png" class="img-fluid my-3" />
					</router-link>
				</div>
			</div>

			<!-- menu -->
			<div class="row" v-if="pages != undefined">
				<Navbar :pages="pages"></Navbar>
			</div>
		</div>
	</div>
</template>

<script>
import Vue from 'vue';
import Navbar from '@/components/Navbar.vue';

export default {
	data: () => ({
		items: [],
	}),

	props: {
		pages: { type: Array },
	},

	components: { Navbar },
};
</script>

<style scoped>
.logo {
	width: 350px;
	margin-top: -120px;
	margin-bottom: 10px;
}
</style>