<template>
	<ul class="navlist">
		<li v-for="(item, i) in items" :key="i">
			<router-link :to="item.path" :class="{'router-link-active': subIsActive(item)}">{{ item.title }}</router-link>
			<ul class="subnavlist">
				<li v-for="child in item.children" :key="child.title">
					<router-link :to="child.path">{{ child.title }}</router-link>
				</li>
			</ul>
		</li>
	</ul>
</template>

<script>
export default {
	data: () => ({
		items: [],
	}),

	props: {
		pages: { type: Array },
	},

	created() {
		// console.clear();
		// console.log(this.$vnode.tag.replace(/vue-component-\d+-/i, ''));

		let last;
		for (let i = 0; i < this.pages.length; i++) {
			let element = this.pages[i];
			if (element.menu_item) {
				if (element.level == 1) {
					element.children = [];
					this.items.push(element);
					last = element;
				}
				if (element.level == 2) {
					last.children.push(element);
				}
			}
		}
	},

	methods: {
		toggleChildren(aa) {
			Vue.set(this.items[aa], 'visible', !this.items[aa].visible);
		},
        
        subIsActive(item) {
            // checks if children of item are active to make parent active also 
            let retval = false;
            if (item.children.length > 0) {
                item.children.forEach(element => {
                    if (this.$route.path.indexOf(element.path)!= -1) {
                        retval = true;
                    }
                });
            }

            return retval;
        }
	},
};
</script>

<style scoped>
ul.navlist {
	font-size: 18px;
	z-index: 99;
}

ul.navlist a {
	text-decoration: none;
    color: white;
	display: block;
}

ul.navlist,
ul.navlist ul,
ul.navlist li {
	margin: 0px;
	padding: 0px;
	list-style-type: none;
}

ul.navlist li {
	float: left;
}

ul.navlist li a {
	padding: 12px 8px;
	text-align: left;
}

ul.navlist li a:hover {
	background-color: #b3d4d4;
}

ul.subnavlist {
	display: none;
}

ul.subnavlist li {
	float: none;
}

ul.navlist li:hover ul.subnavlist {
	display: block;
	position: absolute;
	background-color: #6b705c;
    /* width: 300px; */
	z-index: 99;
}

ul.navlist li:hover ul.subnavlist li a {
	padding: 8px 12px;
}

/* ul.navlist li:hover ul.subnavlist li a:before {
	content: "- ";
} */

/* @media only screen and (min-width: 576px) {
	.hero-img {
		background-size: 100%;
	}
} */

.router-link-active {
	background-color: #a5a58d;
}
</style>
